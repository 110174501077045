<script lang="ts" setup>
import type { ICommonPanel } from '@/interface/commonElements/ICommonPanel'
import { onMounted, ref, type PropType } from 'vue'
import { HeaderPanel } from '@/enums/HeaderPanel'
import { useRoute } from 'vue-router'

const props = defineProps({
  panelHeader: {
    type: Array as PropType<ICommonPanel[]>,
    required: true,
  },
  type: {
    type: String as PropType<HeaderPanel>,
    required: false,
    default: 'macro',
  },
})

const emits = defineEmits(['changeTab'])
const route = useRoute()
const activeTabPanel = ref(props.panelHeader[0])

const isActive = (id: string | number) => {
  return activeTabPanel.value.id === id
}
const changeTabPanel = (panel: ICommonPanel) => {
  activeTabPanel.value = panel
  emits('changeTab', panel)
}

onMounted(() => {
  const id = route.query.id || ''
  if (id) {
    const value = props.panelHeader.find((elem) => elem.id === parseInt(id as string))
    if (value) changeTabPanel(value)
  }
})
</script>

<template>
  <div
    class="grid grid-cols-3 min-[450px]:grid-cols-5 gap-4 mb-8 auto-rows-fr"
  >
    <ElCard
      v-for="element in panelHeader"
      :key="element.id"
      :shadow="isActive(element.id) ? 'always' : 'hover'"
      @click="changeTabPanel(element)"
      :class="[isActive(element.id) ? 'is-active' : 'no-active', 'el-card-tab']"
    >
      <div class="flex flex-col items-center">
         <v-icon :name="element.icon" scale="2" />
        <p
          v-if="type === HeaderPanel.MACRO"
          class="text-[14px] xl:text-[20px] lg:text-xs text-center hidden mt-4 min-[935px]:flex"
        >
          {{ element.title }}
        </p>
      </div>
    </ElCard>
  </div>
</template>

<style lang="scss" scoped>
.el-card-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 150px;
  max-width: 1370px;
  box-shadow: 0 0 0 1px #b4c1c7;
  border-radius: 8px;
  
  &.no-active {
    color: var(--vt-c-text-light-2);
    background-color: transparent;
  }

  &:hover,
  &.is-active {
    box-shadow: 0 0 0 3px var(--vt-c-text-dark);
    color: var(--vt-c-text-dark);
    background-color: var(--vt-c-white);
    --el-card-padding: 30px;

    p {
      font-weight: 600;
    }
  }
}
:deep(.el-card__body) {
  cursor: pointer;

  .is-active & {
    cursor: default;
  }
}

</style>
